import React from "react";
import { motion } from "framer-motion";
import { images } from "../../../Constants";
import "./HeaderWork.scss";

const HeaderWork = () => {
  return (
    <div className="app__flex">
      <div className="home__work-section app__container-width">
        <motion.div
          className="home__work-card"
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          <img src={images.trucks} alt="" />
          <h2>
            {" "}
            <span>DRY VAN TRUCKLOAD</span>{" "}
          </h2>
          <p>
            With multiple trucks servicing Seattle, we are capable of handling
            your freight needs.
          </p>
        </motion.div>
        <motion.div
          className="home__work-card"
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0.4 }}
        >
          <img src={images.warehouse} alt="" />
          <h2>
            {" "}
            <span>WAREHOUSING</span>{" "}
          </h2>
          <p>
            We have warehousing facilities available for long or short term
            storage needs.
          </p>
        </motion.div>
        <motion.div
          className="home__work-card"
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0.6 }}
        >
          <img src={images.container} alt="" />
          <h2>
            {" "}
            <span>Container Drayage</span>{" "}
          </h2>
          <p>We provide various types of container drayage services in the Pacific Northwest.</p>
        </motion.div>
        <motion.div
          className="home__work-card"
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0.8 }}
        >
          <img src={images.commercial} alt="" />
          <h2>
            {" "}
            <span>Reefer Trucks</span>{" "}
          </h2>
          <p>
            Let us handle your reefer needs. We have the capacity to handle your
            reefer needs.
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default HeaderWork;
