import React from "react";
import { Link } from "react-router-dom";
import {
  SiWalmart,
  SiAmazon,
  SiTesla,
  SiTarget,  
  SiFedex,
} from "react-icons/si";
import { images } from "../../Constants";
import { motion } from "framer-motion";

import "./Careers.scss";

const Careers = () => {
  return (
    <div className="app__flex">
      <div className="app__container-width careers__container">
        <motion.h1
          className="careers__title"
          whileInView={{ x: [-50, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0 }}
        >
          Want to work with us?
        </motion.h1>
        <motion.hr
          whileInView={{ x: [-50, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0.1 }}
        />
        <motion.p
          whileInView={{ x: [-100, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0.3 }}
          className="careers__p"
        >
          At Cascadian Transporation, our primary focus is on providing our
          customers with flexible power only trucking solution. We can haul Dry
          vans, Refrigerated trailers and Shipping containers. We continue to
          improve our company, add resources, and train our employees to set
          even higher standards of service.
        </motion.p>
        <motion.button
          className="careers__button"
          whileInView={{ x: [-50, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          <Link className="careers__button-link" to={"/contact"}>
            Contact Us
          </Link>
        </motion.button>

        <motion.img
          className="careers__img"
          src={images.crates}
          alt=""
          whileInView={{ opacity: [0, 1] }}
          transition={{ duration: 1 }}
        />

        <motion.h1
          className="careers__title"
          whileInView={{ x: [-50, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          Our Partners
        </motion.h1>
        <motion.hr
          whileInView={{ x: [-50, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0.2 }}
        />
        <motion.p
          className="careers__p"
          whileInView={{ x: [-50, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          We have worked with a ton of brands making sure they succeed in
          getting the best delivery, storage and customer service anyone can
          provide. Working with these brands has been a great experience for us.
        </motion.p>
        <div className="careers__groups-grid">
          <motion.div
            whileInView={{ y: [50, 0], opacity: [0, 1] }}
            transition={{ duration: 1, delay: 0 }}
            className="careers__groups-item"
          >
            <SiWalmart className="groups__item-icon" />
            <p className="groups__item-title">Walmart</p>
          </motion.div>
          <motion.div
            whileInView={{ y: [50, 0], opacity: [0, 1] }}
            transition={{ duration: 1, delay: 0.1 }}
            className="careers__groups-item"
          >
            <SiAmazon className="groups__item-icon" />
            <p className="groups__item-title">Amazon</p>
          </motion.div>
          <motion.div
            whileInView={{ y: [50, 0], opacity: [0, 1] }}
            transition={{ duration: 1, delay: 0.2 }}
            className="careers__groups-item"
          >
            <SiTesla className="groups__item-icon" />
            <p className="groups__item-title">Tesla</p>
          </motion.div>
          <motion.div
            whileInView={{ y: [50, 0], opacity: [0, 1] }}
            transition={{ duration: 1, delay: 0.3 }}
            className="careers__groups-item"
          >
            <SiTarget className="groups__item-icon" />
            <p className="groups__item-title">Target</p>
          </motion.div>
          <motion.div
            whileInView={{ y: [50, 0], opacity: [0, 1] }}
            transition={{ duration: 1, delay: 0.4 }}
            className="careers__groups-item"
          >
            <SiFedex className="groups__item-icon" />
            <p className="groups__item-title">FedEx</p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
