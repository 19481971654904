import aboutImg from "../Assets/Images/aboutMeEdit.png";
import Biniyam from "../Assets/Images/bini.png";
import betty from "../Assets/Images/betty.png";
import danait from "../Assets/Images/danait.png";
import yoseph from "../Assets/Images/jhey.png";
import commercial from "../Assets/Images/commercial.jpg";
import container from "../Assets/Images/container.png"
import crates from "../Assets/Images/crates.jpg";
import serviceSection from "../Assets/Images/servicesSection.jpg";
import trucks from "../Assets/Images/trucks.png";
import trucksBirdsEyeview from "../Assets/Images/trucksBirdsEyeview.png";
import warehouse from "../Assets/Images/warehouse.png";
import header from "../Assets/Images/header.png";

export default {
  aboutImg, 
  Biniyam,
  betty,
  danait,
  yoseph,
  commercial,
  container,
  crates,
  serviceSection,  
  trucks,
  trucksBirdsEyeview,
  warehouse,
  header,
};
