import React from "react";
import "./OurMessage.scss";
import { motion } from "framer-motion";

import { images } from "../../../Constants";

const OurMessage = () => {
  return (
    <div className=" app__flex">
      <div className="home__our-message-container app__container-width">
        <motion.img
          src={images.aboutImg}
          alt=""
          whileInView={{ x: [-100, 0], opacity: [0, 1] }}
          transition={{ duration: 1 }}
        />
        <motion.div
          className="home__our-message-container-content"
          whileInView={{ x: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 1 }}
        >
          <h1>Our message</h1>
          <hr />
          <p>
            ~ At Cascadian Transporation, we understand the importance of
            reliable, efficient, and cost-effective freight transportation. We use
            cutting-edge technology to ensure that our freight is delivered
            safely and on time.
            <br />
            <br />
            Our experienced staff is knowledgeable and ready to help you with
            any transportation needs you may have. Contact us today to learn
            more about our freight transportation solutions.
            <br />
            <br />
            From<span> Cascadian Transportation LLC</span>
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default OurMessage;
