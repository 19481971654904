import React from "react";
import emailjs from "@emailjs/browser";
import { TextArea, Button } from "semantic-ui-react";
import Swal from "sweetalert2";
import MapSection from '../../Components/map/Map' // import the map here

import "./Contact.scss";
import { motion } from "framer-motion";

const SERVICE_ID = "service_v8erarx";
const TEMPLATE_ID = "template_yt1fqzk";
const USER_ID = "g_rZ1OybRdgv0tZvV";

const location = {
  address: "16704 31ST AVE S APT 235, SEATTLE, WA 98188",
  lat: 47.44,
  lng: -122.28,
};

const Contact = () => {
  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
      (result) => {
        console.log(result.text);
        Swal.fire({
          icon: "success",
          title: "Message Sent Successfully",
        });
      },
      (error) => {
        console.log(error.text);
        Swal.fire({
          icon: "error",
          title: "Ooops, something went wrong",
          text: error.text,
        });
      }
    );
    e.target.reset();
  };
    

  return (
    <div className="app__flex">
      <div className="app__container-width contact__container">
        <motion.h1
          className="contact__title"
          whileInView={{ x: [-50, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0 }}
        >
          Request A Quote
        </motion.h1>
        <motion.hr
          whileInView={{ x: [-50, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0.1 }}
        />
        <motion.p
          whileInView={{ x: [-100, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0.3 }}
          className="contact__p"
        >
          {" "}
          Got a question about how we run things? Looking for to see if we are
          hiring? If you have an questions feel free to contact us, we will
          respond as soon as possible and love to hear from you.
        </motion.p>
        <div className="app__flex">
          <motion.form
            whileInView={{ x: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 1, delay: 0.3 }}
            className="contact__form"
            onSubmit={handleOnSubmit}
          >
            <div className="input__container">
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="contact__input"
                required
              />
            </div>
            <div className="input__container">
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="contact__input"
                required
              />
            </div>
            <div className="input__container">
              <TextArea
                type="text"
                cols="30"
                rows="10"
                name="subject"
                placeholder="Hello, I just want to enquire..."
                className="contact__input"
                required
              />
            </div>
            <Button id="form__btn" className="form__button" type="submit">
              SEND
            </Button>
          </motion.form>
        </div>
        <MapSection location={location} zoomLevel={17} />{" "}
        {/* include it here */}
      </div>
    </div>
  );
};

export default Contact;