import React from "react";
import { images } from "../../Constants";
import ImageCard from "../../Components/ImageCard/ImageCard";
import { motion } from "framer-motion";
import "./About.scss";

const About = () => {
  return (
    <div className="app__flex">
      <div className="app__container-width ">
        <motion.h1
          className="ourteam__title"
          whileInView={{ x: [-200, 0], opacity: [0, 1] }}
          transition={{ duration: 1 }}
        >
          Our Team
        </motion.h1>
        <motion.hr
          whileInView={{ x: [-50, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0.2 }}
        />
        <motion.p
          className="ourteam__p"
          whileInView={{ x: [-300, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0.4 }}
        >
          Say hello to our team that keeps this place up and running. Our team
          of executives and seniors play a huge factor of our success and we
          will do everything we can to ensure yours.{" "}
        </motion.p>

        <div className="team__grid">
          <ImageCard
            image={images.Biniyam}
            title="Bini Mengesha"
            position={"CEO"}
            text="Creator and Founder of Cascadian Transportation LLC, been around for 4 years and has been the core of this company."
            delayTime={0.2}
          />

          <ImageCard
            image={images.betty}
            title="Betty Mengesha"
            position={"Operations Manager"}
            text="Maintains order between the cargo, trucks and crew, is our main point of contact with the customer."
            delayTime={0.4}
          />
          <ImageCard
            image={images.danait}
            title="Danayit Tekeste"
            position={"HR Manager"}
            text="A woman of the people keeping everything smooth with Cascadian Transportation LLC employees. "
            delayTime={0.6}
          />
          <ImageCard
            image={images.yoseph}
            title="Yoseph Berhane"
            position={"Marketing Chief"}
            text="Chief of Marketing for Cascadian Transportation LLC, he is the one who keeps the company in the public eye."
            delayTime={0.8}
          />
        </div>

        <motion.h1
          className="ourteam__title"
          whileInView={{ x: [-200, 0], opacity: [0, 1] }}
          transition={{ duration: 1 }}
        >
          Why we started
        </motion.h1>
        <motion.hr
          whileInView={{ x: [-50, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0.2 }}
        />
        <motion.p
          className="ourteam__p"
          whileInView={{ x: [-300, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0.4 }}
        >
          Our company has been in business for over 4 years and was started by
          Biniyam Mengesha. It was founded as there was a shortage of trucks and
          deliverers in Seattle. So he came up with solution to add the two
          together. We now have 6 trucks, 4 employees and the best working staff
          in Seattle. We are set to make further growths going into the year of
          2023.
        </motion.p>

        <br></br>

        <motion.p
          className="ourteam__p"
          whileInView={{ x: [-300, 0], opacity: [0, 1] }}
          transition={{ duration: 1, delay: 0.4 }}
        >
          If you're looking for a reliable, efficient and cost-effective freight
          transportation company, then look no further than Cascadian
          Transportation. At Cascadian Transportation, we guarantee that your
          freight will arrive safely and on-time, so you can rest assured that
          your cargo is in good hands. At Cascadian Transportation, we strive to
          be the leading freight transportation company in Seattle area and we
          are dedicated to providing our clients with the best possible service.
        </motion.p>
      </div>
    </div>
  );
};

export default About;
